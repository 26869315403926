<template>
  <global-footer class="footer custom-render">
    <template v-slot:links>
      <a>{{ des }}</a>
    </template>
  </global-footer>
</template>

<script>
import {GlobalFooter} from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  },
  data() {
    return {
      des: this.$cFit.footerTips,
    }
  },
}
</script>
