<template>
  <div :class="wrpCls">
    <!-- 等级 -->
<!--    <channel-level />-->
<!--    <span class="channel-type">渠道类型：个人</span>-->
    <!-- 余额-->
<!--    <avatar-balance />-->
    <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
  </div>
</template>

<script>
import AvatarDropdown from './components/AvatarDropdown'
import AvatarBalance from './components/AvatarBalance'
import channelLevel from './components/channelLevel'

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    AvatarBalance,
    channelLevel,
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action',
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    topMenu: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMenu: true,
      currentUser: {},
    }
  },
  computed: {
    wrpCls() {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${this.isMobile || !this.topMenu ? 'light' : this.theme}`]: true,
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.currentUser = {
        name: this.$store.getters.nickname,
      }
    }, 500)
  },
}
</script>
<style lang="less" scoped>
.ant-pro-global-header-index-right {
  display: flex;
}
.channel-type {
  margin: 0 20px;
}
</style>
