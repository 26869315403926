import request from '@/utils/request'
import {fit} from "@/utils/fit";

export function getVerifyKey() {
  return request({
    url: fit.MTGAdminApi + "/verify/key",
    method: 'post',
    data: {},
  })
}

export function getVerifyIMG(key) {
  let currentDate = new Date();
  let currentMilliseconds = currentDate.getTime();
  return fit.MTGAdminApi + "/verify/img?time=" + currentMilliseconds + "&verKey=" + key;
}

export function userNameLogin(parameter) {
  return request({
    url: fit.MTGAdminApi + "/api/auth/login_user_name",
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function sysKeyLogin(parameter) {
  return request({
    url: fit.MTGAdminApi + "/api/auth/sys_key_login",
    method: 'post',
    data: parameter,
  })
}

/**
 *
 * @param parameter onlyInfo=1 只读取基本信息
 * @returns {AxiosPromise}
 */
export function getInfo(parameter) {
  return request({
    url: fit.MTGAdminApi + "/api/user_info",
    method: 'post',
    data: parameter,
  })
}

export function getCurrentUserNav() {
  return request({
    url: fit.MTGAdminApi + "/api/auth/nav",
    method: 'post',
    data: {},
  })
}

export function logout() {
  return request({
    url: fit.MTGAdminApi + "/api/auth/logout",
    method: 'post',
    data: {},
  })
}


//-----------
export function login(parameter) {
  return request({
    url: fit.MTGAdminApi + "/api/auth/login_user_name",
    method: 'post',
    data: parameter
  })
}

export function getSmsCaptcha(parameter) {
  return request({
    url: fit.MTGAdminApi + "",
    method: 'post',
    data: parameter
  })
}

export function get2step(parameter) {
  return request({
    url: fit.MTGAdminApi + "",
    method: 'post',
    data: parameter
  })
}
