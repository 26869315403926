import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import {VueAxios} from './utils/request'
import ProLayout, {PageHeaderWrapper} from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'
import {fit} from './utils/fit'
import { saveAs } from 'file-saver'
import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission'
import './utils/filter'
import './global.less'

Vue.config.productionTip = false


Vue.use(VueAxios)
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)
Vue.prototype.$cFit = fit;

window.umi_plugin_ant_themeVar = themePluginConfig.theme

new Vue({
  router,
  store,
  saveAs,
  i18n,
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
