<template>
  <pro-layout
    :menus="menus"
    :collapsed="collapsed"
    :mediaQuery="query"
    :isMobile="isMobile"
    :handleMediaQuery="handleMediaQuery"
    :handleCollapse="handleCollapse"
    :i18nRender="i18nRender"
    v-bind="settings"
  >
    <template v-slot:menuHeaderRender>
      <div>
        <img src="@/assets/logo.svg" />
        <h1>{{ title }}</h1>
      </div>
    </template>
    <a-layout-content :style="{ height: '100%', margin: '0 0 40px 0' }">
      <multi-tab v-if="multiTab"></multi-tab>
      <transition name="page-transition"> </transition>
    </a-layout-content>
    <template v-slot:headerContentRender>
      <!--            <div>-->
      <!--              <a-tooltip title="刷新页面">-->
      <!--                <a-icon type="reload" style="font-size: 18px;cursor: pointer;" @click="() => { $message.info('只是一个DEMO') }"/>-->
      <!--              </a-tooltip>-->
      <!--            </div>-->
    </template>

    <template v-slot:rightContentRender>
      <right-content :top-menu="settings.layout === 'topmenu'" :is-mobile="isMobile" :theme="settings.theme" />
    </template>

    <!-- custom footer / 自定义Footer -->
    <template v-slot:footerRender>
      <global-footer />
    </template>
    <router-view v-show="!showDialog" />

    <a-modal
      title="请输入支付密码"
      v-model:visible="showDialog"
      :closable="false"
      :keyboard="false"
      :maskClosable="false"
      centered
      v-if="showDialog && !signingModal"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
        <a-form-item label="支付密码">
          <a-input
            v-decorator="['verPass', { rules: [{ required: true, message: '请输入支付密码' }] }]"
            type="password"
            maxlength="6"
          />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </template>
    </a-modal>
    <a-modal
      title="温馨提示"
      v-model:visible="signingModal"
      :closable="false"
      :keyboard="false"
      :maskClosable="false"
      centered
    >
      尊敬的用户您好，为保障您的合法权益，请前往签约网络合作协议，签署成功后即可正常使用推广系统
      <template #footer>
        <a-button @click="handleLogout">退出登录</a-button>
        <a-button type="primary" @click="handleSigning">前往签署</a-button>
      </template>
    </a-modal>
  </pro-layout>
</template>

<script>
import { SettingDrawer } from '@ant-design-vue/pro-layout'
import { i18nRender } from '@/locales'
import { mapGetters, mapState } from 'vuex'
import { SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'
import RightContent from '@/components/GlobalHeader/RightContent'
import GlobalFooter from '@/components/GlobalFooter'
import Watermark from '@/watermark.js'
import moment from 'moment'
import MultiTab from '@/components/MultiTab/MultiTab.vue'
import defaultSettings from '@/config/defaultSettings'
export default {
  name: 'BasicLayout',
  components: {
    SettingDrawer,
    RightContent,
    GlobalFooter,
    MultiTab,
  },
  data() {
    return {
      menus: [],
      collapsed: false,
      title: this.$cFit.sysName,
      multiTab: true,
      settings: {
        theme: 'dark',
        contentWidth: 'Fluid',
        layout: 'sidemenu',
        fixedHeader: true,
        fixSiderbar: true,
        hideHintAlert: false,
        hideCopyButton: false,
      },
      // 媒体查询
      query: {},
      form: this.$form.createForm(this, { name: 'coordinated' }),
      // 是否手机模式
      isMobile: false,
      showDialog: false,
      // 二次验证密码
      signingModal: false,
      //前往签约弹窗
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
    }),
    ...mapGetters(['checkPayVerList', 'waterKey', 'contractStatus']),
  },
  created() {
    const routes = this.mainMenu.find((item) => item.path === '/')
    this.menus = (routes && routes.children) || []
    // 处理侧栏收起状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
  },
  mounted() {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
    if (this.waterKey !== 'UNull') {
      Watermark.set('BRC' + this.waterKey + '.' + moment().format('YY-MM-DD HH:mm'))
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 在回调函数中访问组件实例并保存到变量vm中\
      //判断是否需要签约
      vm.isNeedSigning()
      vm.isNeedDialog(vm.$route.path)
    })
  },
  beforeRouteUpdate(to, from, next) {
    // 在此添加你的独享路由守卫逻辑
    this.isNeedDialog(to.path)
    this.isNeedSigning()
    next() // 调用 next() 表示通过路由跳转
  },
  methods: {
    i18nRender,
    handleMediaQuery(val) {},
    handleCollapse(val) {
      this.collapsed = val
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          const payload = { that: this, verPass: values.verPass }
          this.$store.dispatch('submitPassword', payload).then((res) => {
            if (res) {
              this.showDialog = false
            }
          })
        }
      })
    },
    isNeedDialog(path) {
      const needCheckPass = this.checkPayVerList.includes(path)
      if (needCheckPass) {
        this.$store.dispatch('getSecondVal').then((res) => {
          if (res) {
            this.showDialog = true
          }
        })
      }
    },
    //判断是否需要签约
    isNeedSigning() {
      console.log(this.contractStatus)
      if (this.contractStatus === 1 || this.contractStatus ===2) {
        this.signingModal = true
      }
    },
    handleCancel() {
      this.showDialog = false
      this.$router.back()
    },
    handleLogout() {
      this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    handleSigning() {
      this.$router.push('/contract_signing')
      this.showDialog = false
      this.signingModal = false
    },
  },
}
</script>

<style lang="less" scoped>
@import './BasicLayout.less';

::v-deep(.ant-menu-inline) {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::v-deep(.ant-menu-inline) ::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
