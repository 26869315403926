<template>
  <div class="channel-level">
    <div>渠道等级</div>
    <div class="novice" @click="handleCheckChannel">?</div>
    <!-- <img src="@/assets/icons/" alt="" /> -->
    <div>：新手</div>
    <a-modal v-if="showDialog" title="提示" v-model:visible="showDialog" @ok="handleOk" @cancel="handleCancel">
      渠道等级
    </a-modal>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      showDialog: false,
    }
  },
  methods: {
    handleCheckChannel() {
      this.showDialog = true
    },
    handleOk() {
      this.showDialog = false // 关闭弹框
    },
    handleCancel() {
      this.showDialog = false // 关闭弹框
    },
  },
}
</script>

<style lang="less" scoped>
.channel-level {
  display: flex;
  align-items: center;
}
.novice {
  height: 15px;
  width: 15px;
  // background-color: aqua;
  border-radius: 50%;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ff9305;
  margin: 0 3px 2px;
  font-size: 18px;
  font-weight: bolder;
}
</style>
