import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import {getInfo, logout, sysKeyLogin, userNameLogin} from '@/api/login'
import { ACCESS_TOKEN, CY_TYPE } from '@/store/mutation-types'
import { welcome } from '@/utils/util'

storage.addPlugin(expirePlugin)

const user = {
  state: {
    loginName: '',
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    actionList: [],
    checkPayVerList: [],
    userId: '',
    waterKey: '',
    contractStatus: '',
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { loginName, name, welcome }) => {
      state.loginName = loginName
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES_ACTIONS: (state, actions) => {
      state.actionList = actions
    },
    SET_PAY_VER_LIST: (state, checkPayVerList) => {
      state.checkPayVerList = checkPayVerList
    },
    SET_USER_ID: (state, userId) => {
      state.userId = userId
    },
    SET_CONTRACT_STATUS: (state, contractStatus) => {
      state.contractStatus = contractStatus
    },
    SETWaterKey: (state, waterKey) => {
      state.waterKey = waterKey
    },
  },
  actions: {
    //账号密码登录
    LoginUserName({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        userNameLogin(userInfo)
          .then((response) => {
            if (response['code'] !== 1) {
              reject(response['msg'])
              return
            }
            const result = response.data
            commit('SET_TOKEN', result['accessToken'])
            storage.set(ACCESS_TOKEN, result['accessToken'], new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
            resolve(response['data'])
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    SysKeyLogin({ commit }, parameter) {
      return new Promise((resolve, reject) => {
        sysKeyLogin(parameter)
          .then((response) => {
            if (response['code'] !== 1) {
              reject(response['msg']);
              return
            }
            const result = response.data
            commit('SET_TOKEN', result['accessToken'])
            storage.set(ACCESS_TOKEN, result['accessToken'], new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
            resolve(response['data']);
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo({})
          .then((response) => {
            if (response['code'] !== 1) {
              reject(new Error(response['msg']))
              return
            }
            let resUserData = response['data']
            let actionList = resUserData['actionList']
            if (actionList && actionList.length > 0) {
              // commit('SET_TOKEN', result['accessToken']);
              commit('SET_NAME', { loginName: resUserData.loginName, name: resUserData.nickName, welcome: welcome() })
              commit('SET_AVATAR', resUserData.avatar)
              commit('SET_USER_ID', resUserData.userId)
              commit('SET_TOKEN', resUserData['accessToken'])
              commit('SET_CONTRACT_STATUS', resUserData.contractStatus)
              commit('SETWaterKey', resUserData.waterKey)
              storage.set(ACCESS_TOKEN, resUserData['accessToken'], new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
              commit('SET_ROLES_ACTIONS', actionList)
              commit('SET_PAY_VER_LIST', resUserData.checkPayVerList)
              // 下游
              resolve(resUserData)
            } else {
              console.log('获取用户信息错误1001')
              reject(new Error('getInfo: roles must be a non-null array !'))
            }
          })
          .catch((error) => {
            console.log('获取用户信息错误1002')
            reject(error)
          })
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES_ACTIONS', [])
            storage.remove(ACCESS_TOKEN)
            storage.remove(CY_TYPE)
            resolve()
          })
          .catch((err) => {
            console.log('logout fail:', err)
            // resolve()
          })
          .finally(() => {})
      })
    },
  },
}

export default user
