const getters = {
  isMobile: (state) => state.app.isMobile,
  lang: (state) => state.app.lang,
  theme: (state) => state.app.theme,
  color: (state) => state.app.color,

  token: (state) => state.user.token,
  adminRoleActions: (state) => state.user.actionList,
  avatar: (state) => state.user.avatar,
  nickname: (state) => state.user.name,
  welcome: (state) => state.user.welcome,
  loginName: (state) => state.user.loginName,
  checkPayVerList: (state) => state.user.checkPayVerList,
  userId: (state) => state.user.userId,
  contractStatus: (state) => state.user.contractStatus,
  waterKey: (state) => state.user.waterKey,

  addRouters: (state) => state.permission.addRouters,
  multiTab: (state) => state.app.multiTab,
}
export default getters
