import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import {VueAxios} from './axios'
import {ACCESS_TOKEN} from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 1000 * 60 * 3 // 请求超时时间--3分钟
})

// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const data = error.response.data;
        // 从 localstorage 获取 token
        const token = storage.get(ACCESS_TOKEN);
        let httpStatus = error.response.status;
        switch (httpStatus) {
            case 200:
                break;
            case 403:
                notification.error({
                    message: 'Forbidden403',
                    description: data.message
                });
                break;
            case 401:
                if (!(data.result && data.result.isLogin)) {
                    notification.error({
                        message: 'Unauthorized',
                        description: 'Authorization verification failed'
                    })
                    if (token) {
                        store.dispatch('Logout').then(() => {
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500)
                        })
                    }
                }
                break;
            default:
                notification.error({
                    message: 'system error ' + httpStatus,
                    description: data.message
                })
                break;
        }
    }
    return Promise.reject(error);
}

// request interceptor
request.interceptors.request.use(config => {
    const token = storage.get(ACCESS_TOKEN);
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers[ACCESS_TOKEN] = token;
    }
    let contentType = config.headers['Content-Type'];
    if (config.data?.signal) {
        config.signal = config.data.signal
        delete config.data.signal
    }
    if (!contentType) {
        let formData = new FormData();
        Object.keys(config.data).forEach(key => {
            let mValue = config.data[key];
            if (mValue !== null && mValue !== undefined) {
                formData.append(key, mValue);
            }
        })
        config.data = formData;
    }
    config.headers['Content-Type'] = contentType ? contentType : 'multipart/form-data;charset=UTF-8';
    return config
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
    if (response.data.code === 9001010) {
        const token = storage.get(ACCESS_TOKEN);
        if (token) {
            store.dispatch('Logout').then(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            })
        }
    }
    return response.data;
}, errorHandler);

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request);
    }
}

export default request
export {
    installer as VueAxios,
    request as axios,
}
