import request from '@/utils/request'
import { fit } from '@/utils/fit'

export const API_SECOND_VAL = {
  getCheckPass: (parameter) => {
    return request({
      url: fit.MTGAdminApi + '/api/main/verify_pass',
      method: 'post',
      data: parameter,
    })
  },
  submitSecondValidation: (parameter) => {
    return request({
      url: fit.MTGAdminApi + '/api/main/verify_set_pass',
      method: 'post',
      data: parameter,
    })
  },
}
