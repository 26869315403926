import { API_SECOND_VAL } from '@/api/secondValidation.js'

const secondVal = {
  state: {
    needDialog: false,
  },
  mutations: {
    SET_VAl: (state, needDialog) => {
      state.needDialog = needDialog
    },
  },
  actions: {
    getSecondVal({ commit }) {
      return new Promise((resolve, reject) => {
        API_SECOND_VAL.getCheckPass({}).then((res) => {
          if (res.data.showPayVer === 1) {
            resolve(true)
          }
        })
      })
    },
    // verPass
    submitPassword({ commit }, payload) {
      const { that, verPass } = payload
      return new Promise((resolve, reject) => {
        API_SECOND_VAL.submitSecondValidation({ verPass }).then((res) => {
          const { code, data, msg } = res
          if (code == 1) {
            that.$message.success('密码验证成功')
            resolve(true)
          } else {
            that.$message.error(msg)
          }
        })
      })
    },
  },
}

export default secondVal
