import * as loginService from '@/api/login'
import {BasicLayout, BlankLayout, PageView, RouteView} from '@/layouts'

// 前端路由表 (基于动态)
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  BlankLayout: BlankLayout,
  RouteView: RouteView,
  PageView: PageView,
  '403': () => import(/* webpackChunkName: "error" */ '@/views/403'),
  '404': () => import(/* webpackChunkName: "error" */ '@/views/404'),
  '500': () => import(/* webpackChunkName: "error" */ '@/views/500'),

  //RBAC相关内容
  RBACUserList: () => import('@/views/RBAC/User/list'),
  RBACRoleList: () => import('@/views/RBAC/Role/list'),
  RBACMenuList: () => import('@/views/RBAC/Menu/list'),
  AdminHandleList: () => import('@/views/RBAC/AdminHandleInfo/list'),
  //管理账号相关
  AdminUserSettings: () => import('@/views/user/setting/Index'),
  AdminUserInfo: () => import('@/views/user/setting/Info'),
  AdminUserSecurity: () => import('@/views/user/setting/Security'),

  AdminUserIdentify: () => import('@/views/user/Identify/list'),


  // 你需要动态引入的页面组件
  Workplace: () => import('@/views/dashboard/Workplace'),
  DayInfo: () => import('@/views/dashboard/DataStats/DayInfo/list'),
  LTVList: () => import('@/views/dashboard/DataStats/LTV/list'),
  RetainedUser: () => import('@/views/dashboard/DataStats/RetainedUser/list'),
  RechargeRank: () => import('@/views/dashboard/DataStats/RechargeRank/list'),
  GameServerDayInfo: () => import('@/views/dashboard/DataStats/GameServerDayInfo/list'),

  //推广团队管理
  TGDepartmentList: () => import('@/views/MGTGChannel/Department/list'),
  TGGroupList: () => import('@/views/MGTGChannel/Group/list'),
  TGMemberList: () => import('@/views/MGTGChannel/Member/list'),

  //聊天室模块
  IMGameRoleList: () => import('@/views/ChatRoom/GameRole/list'),

  //财务中心
  FinanceWalletMainHome: () => import('@/views/Finance/WalletMain/home'),
  FinanceGameRatio: () => import('@/views/Finance/GameRatio/home'),
  ChannelBankList: () => import('@/views/Finance/ChannelBank/list'),
  ChannelCoinMG: () => import('@/views/Finance/ChannelCoin/index'),
  FNStlmtHome: () => import('@/views/Finance/GameIncome/StlmtHome/index'),
  FNCashOutInfo: () => import('@/views/Finance/GameIncome/CashOutInfo/list'),
  FNGameStlmtInfo: () => import('@/views/Finance/GameIncome/GameStlmtInfo/list'),
  FNGameStlmtStats: () => import('@/views/Finance/GameIncome/GameStlmtStats/list'),

  //推广游戏管理
  MGGameAllList: () => import('@/views/MGGame/AllGame/list'),
  MGGameMyList: () => import('@/views/MGGame/MyGame/list'),
  MGChannelGameList: () => import('@/views/MGGame/ChannelGame/list'),
  MGGameTGWebSite: () => import('@/views/MGGame/WebSite/list'),

  //推广数据明细
  DITGGameRechargeList: () => import('@/views/DataInfoCPS/GameRecharge/list'),
  DITGGameRoleList: () => import('@/views/DataInfoCPS/GameRole/list'),
  DITGGameUserList: () => import('@/views/DataInfoCPS/GameUser/list'),
  DITGGameRoleLogList: () => import('@/views/DataInfoCPS/GameRoleLog/list'),
  DITGTeamDepartmentList: () => import('@/views/DataInfoCPS/TeamData/department'),
  DITGTeamGroupList: () => import('@/views/DataInfoCPS/TeamData/group'),
  DITGTeamMemberList: () => import('@/views/DataInfoCPS/TeamData/member'),

  //游戏测试资源管理
  GSupportAccountList: () => import('@/views/GSupport/GameAccount/list'),
  GSupportBalanceList: () => import('@/views/GSupport/Balance/list'),
  GSupportRecordList: () => import('@/views/GSupport/Record/list'),

  //玩家福利管理
  GWelfarePlayGameList: () => import('@/views/GWelfare/PlayGame/list'),
  GWelfareRoleLoginList: () => import('@/views/GWelfare/RoleLogin/list'),
  GWelfarePayFirstList: () => import('@/views/GWelfare/PayFirst/list'),
  GWelfarePaySingleList: () => import('@/views/GWelfare/PaySingle/list'),
  GWelfareTotalPayDayList: () => import('@/views/GWelfare/TotalPayDay/list'),
  GWelfareTotalPayAllList: () => import('@/views/GWelfare/TotalPayAll/list'),
  GWelfarePayRebateList: () => import('@/views/GWelfare/PayRebate/list'),
  GWelfareWeekCardList: () => import('@/views/GWelfare/WeekCard/list'),
  GWelfareMonthCardList: () => import('@/views/GWelfare/MonthCard/list'),
  GWelfareRoleLevelList: () => import('@/views/GWelfare/RoleLevel/list'),

  GWelfareScorePropRoleList: () => import('@/views/GWelfare/ScoreProp/GameRole/list'),
  GWelfareScorePropTempList: () => import('@/views/GWelfare/ScoreProp/PropTemp/list'),
  GWelfareScoreRoleList: () => import('@/views/GWelfare/ScoreProp/RoleScore/list'),
  GWelfareScoreRolePropSendList: () => import('@/views/GWelfare/ScoreProp/SendInfo/list'),

  GWelfareFundList: () => import('@/views/GWelfare/Fund/list'),
  GWelfareForeverList: () => import('@/views/GWelfare/Forever/list'),

  MGOTSimulatorUserOpenList: () => import('@/views/MGOperate/SimulatorUserOpen/list'),
  MGOTBatchRegisterList: () => import('@/views/MGOperate/BatchRegister/list'),
  MGOTBindGameUserHome: () => import('@/views/MGOperate/BindGameUser/home'),

  MGAppIndex: () => import('@/views/MGAPP/Main/index'),
}

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*',
  redirect: '/404',
  hidden: true
}

//根级菜单
const rootRouter = {
  key: '',
  name: 'index',
  path: '',
  component: 'BasicLayout',
  redirect: '/ds',
  meta: {
    title: '首页'
  },
  children: []
}

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = token => {
  return new Promise((resolve, reject) => {
    loginService
      .getCurrentUserNav(token)
      .then(res => {
        let resMenu = res['data']['menu'];
        const menuNav = [];
        const childrenNav = [];
        // 后端数据, 根级树数组,  根级 PID
        listToTree(resMenu, childrenNav, 0);
        rootRouter.children = childrenNav;
        menuNav.push(rootRouter);
        const routers = generator(menuNav);
        routers.push(notFoundRouter);
        resolve(routers);
      })
      .catch(err => {
        reject(err);
      })
  })
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  return routerMap.map(item => {
    const {title, show, hideChildren, hiddenHeaderContent, target, icon} = item.meta || {}
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path || `${(parent && parent.path) || ''}/${item.key}`,
      // 路由名称，建议唯一
      name: item.name || item.key || '',
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: constantRouterComponents[item.component || item.key] || (() => import(`@/views/${item.component}`)),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        permission: item.name
      }
    }
    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach(item => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: []
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id)
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
}
