<template>
  <a-dropdown placement="bottomRight">
    <span class="ant-pro-account-avatar" style="cursor: pointer"> 余额 </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item>余额：3.18</a-menu-item>
        <a-menu-item>钱包余额:.18</a-menu-item>
        <a-menu-item>押金金额:.18</a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
export default {
  props: {},
  methods: {},
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  :deep(.action) {
    margin-right: 8px;
  }
  :deep(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}
</style>
